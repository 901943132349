html,
body {
  height: 100%;
}
body {
  display: grid;
}
.check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 24px;
  height: 24px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
  margin-right: 10px;
}
/* .check:before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(34,50,84,0.03);
  opacity: 0;
  transition: opacity 0.2s ease;
} */
.check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ffffff;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}
.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}
.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}
.check:hover:before {
  opacity: 1;
}
.check:hover svg {
  stroke: #ffffff;
}
.funkyCheckbox:checked + .check svg {
  stroke: #ffffff;
}
.funkyCheckbox:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}
.funkyCheckbox:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}
